import type { AppBarProps } from '@mui/material/AppBar';
import AppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import Button from 'components/dist/atoms/Button';
import Icon from 'components/dist/atoms/Icon';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { Route } from 'src/constants/ui';
import { Permission } from 'src/constants/user';

import { SearchAutocomplete } from '../search/search-autocomplete';
import { LoanRoleGuard } from '../user/loan-role-guard';
import { UserRoleGuard } from '../user/user-role-guard';
import { AccountPopover } from './account-popover';
import { DashboardNavbarBreadcrumbs } from './dashboard-navbar-breadcrumbs';
import { Notifications } from './notifications';

interface DashboardNavbarProps extends AppBarProps {
  onToggle: () => void;
  sidebarOpen?: boolean;
  showGoBackButton?: boolean;
  noSearch?: boolean;
  noSideBar?: boolean;
}

const DashboardNavbarRoot = styled(AppBar)(({
  backgroundColor: 'white',
  color: 'black',
  boxShadow: 'none'
}));

const AccountButton = () => {
  return (
    <>
      <UserRoleGuard permission={Permission.SeePendingInvites}>
        <Notifications />
      </UserRoleGuard>
      <AccountPopover />
    </>
  );
};


export const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onToggle, showGoBackButton, noSearch, noSideBar, sidebarOpen, ...other } = props;
  const router = useRouter();
  const handleGoBackClick = () => {
    // if we have a referrer, go back to it
    // otherwise go back to home page
    if (document.referrer) {
      router.back();
    } else {
      router.push({
        pathname: Route.HOME
      });
    }
  };

  const isLoanLink = ([Route.SINGLE_LOAN, Route.SINGLE_LOAN_MESSAGES, Route.SINGLE_LOAN_TASKS, Route.SINGLE_LOAN_TASKS_TYPE] as string[]).includes(router.pathname)

  return <DashboardNavbarRoot
    className="border-b border-gray-200 bg-white"
    sx={(theme) => ({
      left: {
        lg: noSideBar ? '0px' : '256px',
      },
      width: {
        lg: noSideBar ? '100%' : 'calc(100% - 256px)',
      },
      transition: theme.transitions.create(['left', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen
      }),
      ...((!sidebarOpen && !noSideBar) && {
        width: {
          lg: 'calc(100% - 58px)',
        },
        left: {
          lg: "58px"
        },
        transition: theme.transitions.create(['left', 'width'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
      })
    })}
    {...other}
  >
    <Stack
      row
      items='center'
      space="sm"
      className='h-[54px] min-h-[54px] left-0 p-2'
    >
      <Tooltip.Provider>
        <Tooltip>
          <Tooltip.Trigger asChild>
            <Button className="text-primary hover:text-white bg-transparent mr-1 px-1 py-1 rounded-sm xl:hidden"
              onClick={onToggle}
              data-testid="sidebar-toggle-trigger"
              size="custom">
              <Icon name="Menu" width={20} height={20} strokeWidth={2} />
            </Button>
          </Tooltip.Trigger>
          <Tooltip.Content>
            <Text size="sm">
              {open ? 'Close' : 'Open'} Menu
            </Text>
          </Tooltip.Content>
        </Tooltip>
      </Tooltip.Provider>
      {showGoBackButton &&
        <Button
          onClick={handleGoBackClick}
          variant='ghost'>
          <Icon name="NavArrowLeft" width={20} height={20} />
        </Button>}
      <DashboardNavbarBreadcrumbs />
      <Stack className='flex-1' space="lg" row items="center">
        <Stack className='flex-1' justify='center' row>
          {(!noSearch && !isLoanLink) && <LoanRoleGuard
            permission={Permission.Search}>
            <div className='max-w-md w-full relative'>
              <SearchAutocomplete />
            </div>
          </LoanRoleGuard>}
        </Stack>
        <AccountButton />
      </Stack>
    </Stack>
  </DashboardNavbarRoot>
};
